require('./bootstrap');

$(document).ready(function(){
    var scroll = $(window).scrollTop();
    const $navbar = $('.main-nav');
    const $bottomNavbar = $('.bottom-nav');

    if(document.URL.includes("brustkrebs")){
        $(".main-footer").addClass("brustkrebs-page");
        $(".wavy-white-background-top-footer").addClass("mb-0");
    }
    if(document.URL.includes("genetische-risikofaktoren")){
        //$(".main-footer").addClass("bg-white");
        //$(".wavy-white-background-top-footer").addClass("mb-0");
    }
    if(document.URL.includes("sport-und-brustkrebs")){
        $(".main-footer").addClass("bg-white");
    }

    if (scroll >= 25) {
        $(".main-nav").addClass("scrolled");
        $(".bottom-nav").addClass("scrolled");
    }else{
        $(".main-nav").removeClass("scrolled");
        $(".bottom-nav").removeClass("scrolled");
    }

    $(window).scroll(function() {
        var scroll = $(window).scrollTop();
        if (scroll >= 25) {
            $(".main-nav").addClass("scrolled");
            $(".bottom-nav").addClass("scrolled");
        }else{
            $(".main-nav").removeClass("scrolled");
            $(".bottom-nav").removeClass("scrolled");
        }
    });

    $(".hamburger").click(function(){
        $(this).toggleClass("is-active");
        $('.nav-links').toggleClass("show");
        $('body').toggleClass("menu-open");
        $('#main-nav').toggleClass("menu-open");
        $('#bottom-nav').toggleClass("menu-open");
    });

    /*$('a[href^="#"]').on('click', function(e) {
        e.preventDefault();

        const scrollTop =
            $($(this).attr('href')).position().top -
            $navbar.outerHeight() - $bottomNavbar.outerHeight();

        $('html, body').animate({ scrollTop });
    });*/

    $(".sliding-link").click(function(e) {
        e.preventDefault();
        var aid = $(this).attr("href");
        $('html,body').animate({scrollTop: ($(aid).offset().top - ($navbar.outerHeight()))},'slow');

        $('.hamburger').removeClass("is-active");
        $('.nav-links').removeClass("show");
        $('body').removeClass("menu-open");
        $('#main-nav').removeClass("menu-open");
        $('#bottom-nav').removeClass("menu-open");
    });
    $(".sliding-link-offset").click(function(e) {
        e.preventDefault();
        var aid = $(this).attr("href");
        $('html,body').animate({scrollTop: ($(aid).offset().top - ($navbar.outerHeight()) - 50)},'slow');

        $('.hamburger').removeClass("is-active");
        $('.nav-links').removeClass("show");
        $('body').removeClass("menu-open");
        $('#main-nav').removeClass("menu-open");
        $('#bottom-nav').removeClass("menu-open");
    });


    var cards = document.querySelectorAll("a");
    var modal = document.getElementById("redirectModal");

    for (var i = 0; i < cards.length; i++) {
        var card = cards[i];
        card.onclick = function () {
            //console.log("enter this.element: " + this.toLocaleString() + " and this.link: " + this.getAttribute("href") + " and card: " + card.getAttribute("href"));
            if(this.toLocaleString().includes("mama")==false
                && !this.toLocaleString().includes("astrazeneca")
                && !this.toLocaleString().includes("storyblok")
                && !this.toLocaleString().includes("atemwege")
                && !this.toLocaleString().includes("eigenart")
                && document.getElementById("redirectModal").classList.contains("modal-open")==false
                && this.toLocaleString().includes("cookie")==false)
            {
                document.getElementById("body").classList.add("no-scrolling");

                modal.style.display = "block"; //open modal
                document.getElementById("continueButtonRedirectModal").setAttribute("href", this.toLocaleString()); //setting the redirect link
                document.getElementById("redirectModal").classList.add("modal-open");
                return false; //override standard handling
            }
        }
    }

    // Get elements that closes the modal
    var span = document.getElementsByClassName("redirect-modal-close")[0];
    var cancelButton = document.getElementById("cancelButtonRedirectModal");

    // When the user clicks, close the modal
    span.onclick = function (){
        closeModal();
        return false; //override standard handling
    }
    cancelButton.onclick = function() {
        closeModal();
        return false; //override standard handling
    }

    // When the user clicks anywhere outside of the modal, close it
    window.onclick = function(event) {
        if (event.target == modal) {
            modal.style.display = "none";
            document.getElementById("redirectModal").classList.remove("modal-open");
        }
    }

    function closeModal(){
        document.getElementById("body").classList.remove("no-scrolling");
        modal.style.display = "none";
        document.getElementById("redirectModal").classList.remove("modal-open");
    }
});
